import { Component, OnDestroy, OnInit } from '@angular/core';
import { THEME_ICONS } from '@shared/faIcons';
import { SelettivaService } from '@core/http-gen/services/selettiva.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, forkJoin, interval, Observable, of, Subscription } from 'rxjs';
import { SelettivaVolontarioDto } from '@core/http-gen/models/selettiva-volontario-dto';
import { SelettivaDto } from '@core/http-gen/models/selettiva-dto';
import { DispositivoMobileService } from '@core/http-gen/services/dispositivo-mobile.service';
import { environment } from '@env';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { NgxAuthService } from 'ngx-auth-utils';
import { map, switchMap, tap } from 'rxjs/operators';
import { ONE_DAY, ONE_HOUR, ONE_MINUTE, ONE_MONTH, ONE_SECOND } from '@shared/utils/time.utils';
import { v4 as uuidv4 } from 'uuid';
import { LazyLoadEvent } from 'primeng/api';
import { SelettivaListItemDto } from '@core/http-gen/models';
import { SelettivaData } from './display-selettive.models';
import { PermissionCheckerService } from '@shared/permissions/permission-checker.service';
import { Permesso, PermessoValore } from '@shared/types/auth.models';
import { getValueOrError } from '@core/errors/exception';
import Map from 'ol/Map';
import View from 'ol/View';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { fromLonLat } from 'ol/proj';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Icon, Style } from 'ol/style';

const STANDBY_NIGHT_START = 23;
const STANDBY_NIGHT_END = 5;

@UntilDestroy()
@Component({
    selector: 'app-display-seslettive',
    templateUrl: './display-selettive.component.html',
    styleUrls: ['./display-selettive.component.scss'],
})
/* eslint-disable no-console */
export class displaySelettiveComponent implements OnInit, OnDestroy {
    readonly icons = THEME_ICONS;
    private mouseMoveTimeout: number | undefined;

    public partecipanti: Array<SelettivaVolontarioDto> = [];
    public nonPartecipanti: Array<SelettivaVolontarioDto> = [];
    public infoSelettiva?: SelettivaDto;
    public gravitaEvento?: string | undefined;

    public selettivaList: Array<SelettivaListItemDto> = [];
    public selettivaListColumns: Array<unknown> = [{ field: 'id', header: 'Selettiva' }];
    private _selectedSelettivaId = 0;
    private _lastVisibleSelettivaId = 0;
    public selectedSelettiva: SelettivaListItemDto | null = null;

    public canViewStorico = false;
    public canViewVigiliInArrivo = false;

    private sub?: Subscription;
    private monitorDevInterval$ = new BehaviorSubject<number>(1500);

    public clock = this.getFormattedDate(new Date(), false);

    public errorBox = false;
    public errorTitle = 'Ops, qualcosa è andato storto';
    public errorDescription = 'Qualcosa è andato storto, riprova più tardi';

    public nightStandbyStatus = false;
    private nightStandbyTimeout: number | undefined;
    public pageContent = true;

    map!: Map;

    // View settings:
    // Right side
    public eventEssentialDetails = true;
    public responderCount = true;
    public mapBox = true;

    // Left side
    public presentiList = true;
    public nonPresentiList = true;
    public eventDetails = false;
    public notificationsCount = false;

    constructor(
        private selettivaService: SelettivaService,
        private dispositivoService: DispositivoMobileService,
        private authService: NgxAuthService,
        private _permissionChecker: PermissionCheckerService
    ) {
        this.setGeneralView();
        this.scheduleRefresh(3);
    }

    ngOnInit(): void {
        this.canViewStorico = this._permissionChecker.hasThePermission(
            Permesso.StoricoSelettive,
            PermessoValore.Lettura,
            this.authService.snapshot.user.tokenData.permessoList
        );

        this.canViewVigiliInArrivo = this._permissionChecker.hasThePermission(
            Permesso.VigiliInArrivo,
            PermessoValore.Lettura,
            this.authService.snapshot.user.tokenData.permessoList
        );

        if (!this.canViewVigiliInArrivo || !this.canViewStorico) {
            this.showError('Errore di permessi', 'Settare i permessi: StoricoSelettive e VigiliInArrivo');
            return;
        } else {
            this.hideError();
        }

        // Remove the previous event listener
        navigator.serviceWorker.removeEventListener('message', () => this.onBackgroundMessage());

        // Add the event listener
        navigator.serviceWorker.addEventListener('message', () => this.onBackgroundMessage());

        this._startCurrentDateTime();

        this._updateData((infoSelettiva) => this.startPolling(new Date(infoSelettiva.dataOra)));

        this.requestPermission();

        setTimeout(() => {
            this.refreshMap();
            this.startEvents();
            this.listenForNotifications();
        }, 500);
    }

    private showError(title: string, description: string): void {
        this.errorBox = true;
        this.errorTitle = title;
        this.errorDescription = description;
    }

    private hideError(): void {
        this.errorBox = false;
        this.errorTitle = '';
        this.errorDescription = '';
    }

    private startEvents(): void {
        // Hide mouse cursor after 5 seconds of inactivity
        document.body.addEventListener('mousemove', () => {
            document.body.style.cursor = 'default';
            clearTimeout(this.mouseMoveTimeout);
            this.mouseMoveTimeout = window.setTimeout(() => {
                document.body.style.cursor = 'none';
            }, 3000);
        });

        // Add event listener for resize page
        window.addEventListener('resize', () => {
            this.refreshMap();
        });

        // Check if is online or offline
        window.addEventListener('offline', () => {
            this.showError('Errore di connessione', 'Non sei connesso ad internet, controlla la tua connessione');
        });
        window.addEventListener('online', () => {
            this.hideError();
            window.location.reload();
        });

        // Launch check for night standby mode
        this.nightStandby();
    }

    private setGeneralView(): void {
        // Get query params
        const urlParams = new URLSearchParams(window.location.search);

        if (urlParams.has('mapBox')) {
            this.mapBox = urlParams.get('mapBox') === 'true';
        }
        if (urlParams.has('presentiListBox')) {
            this.presentiList = urlParams.get('presentiListBox') === 'true';
        }
        if (urlParams.has('nonPresentiListBox')) {
            this.nonPresentiList = urlParams.get('nonPresentiListBox') === 'true';
        }
        if (urlParams.has('eventDetailsBox')) {
            this.eventDetails = urlParams.get('eventDetailsBox') === 'true';
        }
        if (urlParams.has('notificationsCountBox')) {
            this.notificationsCount = urlParams.get('notificationsCountBox') === 'true';
        }

        if (this.eventDetails) {
            this.eventEssentialDetails = false;
            this.presentiList = false;
            this.nonPresentiList = false;
        }

        if (this.presentiList && this.nonPresentiList) {
            this.responderCount = false;
        }
    }

    private hideSensitiveData(): void {
        const listComponents = ['eventNote', 'callerName', 'callerPhone'];

        for (const component of listComponents) {
            const element = document.getElementById(component);
            if (element) {
                element.classList.add('hidden-text');
            }
        }
    }

    private showSensitiveData(): void {
        const listComponents = ['eventNote', 'callerName', 'callerPhone'];

        for (const component of listComponents) {
            const element = document.getElementById(component);
            if (element) {
                element.classList.remove('hidden-text');
            }
        }
    }

    public onMapReady(map: Map): void {
        // eslint-disable-next-line no-console
        this.map = map;
        this.setView();
    }

    private setView(): void {
        if (!this.map) return;
        if (!this.infoSelettiva?.localizzazionePosizione) return;

        this.refreshMap();
        this.map.setView(
            new View({
                center: fromLonLat([this.infoSelettiva?.localizzazionePosizione?.x, this.infoSelettiva?.localizzazionePosizione?.y]),
                zoom: 16,
            })
        );
        this.addMarker();
    }

    private addMarker(): void {
        // Remove previous markers
        this.map.getLayers().forEach((layer) => {
            if (layer instanceof VectorLayer) {
                this.map.removeLayer(layer);
            }
        });

        if (!this.infoSelettiva?.localizzazionePosizione) return;

        const marker = new Feature({
            geometry: new Point(
                fromLonLat([this.infoSelettiva?.localizzazionePosizione?.x, this.infoSelettiva?.localizzazionePosizione?.y])
            ),
        });

        const markerStyle = new Style({
            image: new Icon({
                anchor: [0.5, 1],
                src: '/assets/icons/marker.png',
            }),
        });

        marker.setStyle(markerStyle);

        const vectorLayer = new VectorLayer({
            source: new VectorSource({
                features: [marker],
            }),
        });

        this.map.addLayer(vectorLayer);
    }

    private refreshMap(): void {
        if (this.map) {
            this.map.updateSize();
        }
    }

    private _startCurrentDateTime(): void {
        let lastMinute = new Date().getMinutes();

        setInterval(() => {
            const now = new Date();

            if (lastMinute == now.getMinutes()) {
                return;
            }

            lastMinute = now.getMinutes();
            this.clock = this.getFormattedDate(now, false);
        }, 1000);
    }

    public getFormattedDate(date: Date | string | undefined, seconds = false): string {
        if (!date) return '';

        if (typeof date === 'string') {
            date = new Date(date);
        }

        let strTime = date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0');

        if (seconds) {
            strTime += ':' + date.getSeconds().toString().padStart(2, '0');
        }

        const strDate =
            date.getDate().toString().padStart(2, '0') +
            '/' +
            (date.getMonth() + 1).toString().padStart(2, '0') +
            '/' +
            date.getFullYear().toString().padStart(2, '0');

        return strDate + ' ' + strTime;
    }

    public getElapsedTimeFromNow(date: Date | string | undefined): string {
        if (!date) return '';

        if (typeof date === 'string') {
            date = new Date(date);
        }

        const now = new Date();
        const diff = now.getTime() - date.getTime();

        // Less than 1 minute
        if (diff < ONE_MINUTE) {
            return 'adesso';
        }

        // Less than 1 hour
        if (diff < ONE_MINUTE * 60) {
            const minutes = Math.floor(diff / ONE_MINUTE);
            if (minutes === 1) return '1 minuto fa';
            else return minutes + ' minuti fa';
        }

        // less than 1 day
        if (diff < ONE_HOUR * 24) {
            const hours = Math.floor(diff / ONE_HOUR);
            if (hours === 1) return '1 ora fa';
            else return hours + ' ore fa';
        }

        // Less than 1 month, 30 days (i know it's not accurate)
        if (diff < ONE_DAY * 30) {
            const days = Math.floor(diff / ONE_DAY);
            if (days === 1) return '1 giorno fa';
            else return days + ' giorni fa';
        }

        // Less than 1 year, 365 days (i know it's not accurate)
        if (diff < ONE_DAY * 365) {
            const months = Math.floor(diff / ONE_MONTH);
            if (months === 1) return '1 mese fa';
            else return months + ' mesi fa';
        }

        // More than 1 year, is impossible to reach this point, but just in case
        const years = Math.floor(diff / ONE_DAY / 365);
        if (years === 1) return '1 anno fa?!';
        else return years + ' anni fa?!';
    }

    /** Set refresh at a specific delta time in hours */
    private scheduleRefresh(hours: number): void {
        const timeUntilRefresh = ONE_HOUR * hours;

        setTimeout(function () {
            location.reload();
        }, timeUntilRefresh);
    }

    private onBackgroundMessage(): void {
        this.monitorDevInterval$ = new BehaviorSubject<number>(3000);
        this.startPolling(new Date());
    }

    private startPolling(pollingStartDate: Date): void {
        if (this.sub) {
            this.sub.unsubscribe();
        }

        this.sub = this.monitorDevInterval$
            .pipe(
                switchMap((value) => interval(value)),
                tap(() => {
                    this.checkTimerIncrement(pollingStartDate);
                    this._updateData(() => ({}));
                })
            )
            .subscribe();
    }

    /*
     * Per i primi 5 minuti (nei quali ci aspettiamo di ricevere la maggior parte delle risposte) il polling è ogni 10 secondi
     * Dai 5 ai 10 minuti ogni 20 secondi
     * Dopo i 15 minuti ci si ferma
     *
     * */
    private checkTimerIncrement(startDate: Date): void {
        const elapsed = new Date().getTime() - startDate.getTime();

        if (elapsed > ONE_MINUTE * 15) {
            // Run trigger to end the alert call
            this.endOfAlertCall();

            this.sub?.unsubscribe();

            return;
        } else {
            // Alert is in progress
            this.inProgressAlertCall();

            if (elapsed > ONE_MINUTE * 5) {
                this.monitorDevInterval$.next(ONE_SECOND * 20);
                return;
            }

            this.monitorDevInterval$.next(ONE_SECOND * 10);
            return;
        }
    }

    private endOfAlertCall(): void {
        // Hide the event details after 15 minutes
        this.hideSensitiveData();

        // Wait plus 15 minutes to reactivate the night standby mode
        // Enable the night standby mode at the end of selettiva
        setTimeout(() => {
            this.nightStandby();
        }, ONE_MINUTE * 15);
    }

    // Can be called multiple times
    private inProgressAlertCall(): void {
        this.showSensitiveData();
        this.nighStandbyWakeOnCall();
    }

    private requestPermission(): void {
        const messaging = getMessaging();

        getToken(messaging, { vapidKey: environment.firebase.vapidKey })
            .then((currentToken) => {
                if (currentToken) {
                    const oldToken = localStorage.getItem('tokenDispositivoMobile');
                    let deviceUid = localStorage.getItem('deviceUid');
                    if (!deviceUid) {
                        deviceUid = uuidv4();
                        localStorage.setItem('deviceUid', deviceUid);
                    }

                    if (oldToken !== currentToken) {
                        localStorage.setItem('tokenDispositivoMobile', currentToken);
                        this.dispositivoService
                            .apiDispositivoMobileInsertOrUpdatePost({
                                body: {
                                    deviceUId: deviceUid,
                                    newToken: currentToken,
                                    tipoDispositivo: 3,
                                    tipoUpdate: 3,
                                },
                            })
                            .subscribe();
                    }
                } else {
                    console.log('No registration token available. Request permission to generate one.');
                    this.showError('Errore di permessi', 'Autorizzazione non riuscita per le notifiche');
                }
            })
            .catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
                this.showError('Errore di permessi', 'Errore durante la richiesta di permessi per le notifiche');
            });
    }

    private listenForNotifications(): void {
        const messaging = getMessaging();
        onMessage(messaging, (payload) => {
            if (payload.data?.tipoPushNotification === '99' || payload.data?.tipoPushNotification === '98') {
                this.monitorDevInterval$ = new BehaviorSubject<number>(3000);
                this.startPolling(new Date());
            }
        });
    }

    /**
     * Legge l'id dell'ultima selettiva visibile dall'utente, se è cambiato, ricarica la lista e la nuova selettiva
     * altrimenti carica solamente i dati della selettiva selezionata
     */
    public _updateData(extraActions?: (infoSelettiva: SelettivaDto) => void): void {
        //TODO gestire più selettive?
        this.selettivaService
            .apiSelettivaSelettivaLastIdGet()
            .pipe(
                untilDestroyed(this),
                switchMap((selettivaId: number) => {
                    if (this._lastVisibleSelettivaId != selettivaId) {
                        this._lastVisibleSelettivaId = selettivaId;
                        this._selectedSelettivaId = selettivaId;

                        return of(selettivaId);
                    } else {
                        return of(this._selectedSelettivaId);
                    }
                }),
                switchMap((selettivaId: number) => {
                    // Aggiorno i dettagli della selettiva
                    return this._refreshSelettivaData(selettivaId, extraActions);
                })
            )
            .subscribe();
    }

    /** Order the partecipanti and nonPartecipanti lists, put 'autista' first in the list */
    private orderAllLists(): void {
        this.partecipanti = this.partecipanti.sort((a) => {
            if (a.tipoPartecipante === 2) {
                return -1;
            } else {
                return 1;
            }
        });

        this.nonPartecipanti = this.nonPartecipanti.sort((a) => {
            if (a.tipoPartecipante === 2) {
                return -1;
            } else {
                return 1;
            }
        });
    }

    private getLimitList(): number {
        let documentHeight = document.documentElement.clientHeight || 0;
        const titleHeight = document.getElementById('presentiListTitle')?.clientHeight || 0;

        let itemHeight = 78; // Default value
        if (document.getElementById('presentiListItem') && document.getElementById('presentiListItem')?.clientHeight !== 0) {
            itemHeight = document.getElementById('presentiListItem')?.clientHeight || 0;
        }
        if (document.getElementById('nonPresentiListItem') && document.getElementById('nonPresentiListItem')?.clientHeight !== 0) {
            itemHeight = document.getElementById('nonPresentiListItem')?.clientHeight || 0;
        }

        // Add margin for security
        documentHeight -= 65;

        // calculate the number of items that can be shown
        const maxItems = Math.floor((documentHeight - titleHeight) / itemHeight);

        return maxItems;
    }

    private limitListPresenti(count: number): void {
        if (this.partecipanti.length == 0) {
            return;
        }

        const presentiCount = this.partecipanti.length;

        if (presentiCount > count) {
            this.partecipanti = this.partecipanti.slice(0, count - 1);

            const remaining = presentiCount - count + 1;
            this.partecipanti.push({
                cognome: '+' + remaining + ' vigili',
                nome: '',
                isPartecipante: true,
                tipoPartecipante: -1,
                nomeUtente: '',
                utenteId: 0,
                dataOra: '',
            });
        }
    }

    private limitListNonPresenti(count: number): void {
        if (this.nonPartecipanti.length == 0) {
            return;
        }

        const nonPresentiCount = this.nonPartecipanti.length;

        if (nonPresentiCount > count) {
            this.nonPartecipanti = this.nonPartecipanti.slice(0, count - 1);

            const remaining = nonPresentiCount - count + 1;
            this.nonPartecipanti.push({
                cognome: '+' + remaining + ' vigili',
                nome: '',
                isPartecipante: true,
                tipoPartecipante: -1,
                nomeUtente: '',
                utenteId: 0,
                dataOra: '',
            });
        }
        // Add a fake user to the lis
    }

    /** Esegue le chiamate per aggiornare i dati della selettiva, e li imposta negli oggetti visibili nella pagina */
    private _refreshSelettivaData(
        selettivaId: number,
        extraActions?: (infoSelettiva: SelettivaDto) => void
    ): Observable<SelettivaData | null> {
        if (!isNaN(selettivaId) || selettivaId != 0) {
            return forkJoin({
                partecipanti: this.canViewVigiliInArrivo
                    ? this.selettivaService.apiSelettivaSelettivaVolontarioListGet({
                          selettivaId,
                      })
                    : of([]),

                infoSelettiva:
                    this.infoSelettiva == null || selettivaId != this.infoSelettiva.id
                        ? this.selettivaService.apiSelettivaSelettivaByIdGet({ selettivaId })
                        : of(getValueOrError(this.infoSelettiva)),
            }).pipe(
                map((selettivaData: SelettivaData) => {
                    if (selettivaData != null) {
                        this.infoSelettiva = selettivaData.infoSelettiva;
                        if (this.canViewVigiliInArrivo) {
                            this.partecipanti = selettivaData.partecipanti.filter((p) => p.isPartecipante);
                            this.nonPartecipanti = selettivaData.partecipanti.filter((p) => !p.isPartecipante);
                        } else {
                            this.partecipanti = [];
                            this.nonPartecipanti = [];
                        }

                        if (extraActions) {
                            extraActions(this.infoSelettiva);
                        }

                        this.orderAllLists();

                        // Limit the number of items in the list
                        const maxItems = this.getLimitList();
                        this.limitListPresenti(maxItems);
                        this.limitListNonPresenti(maxItems);

                        this.gravitaEvento = this.getGravita(selettivaData.infoSelettiva.eventoCodice);
                    } else {
                        this.infoSelettiva = undefined;
                        this.partecipanti = [];
                        this.nonPartecipanti = [];
                    }

                    // Set map view
                    this.setView();
                    return selettivaData;
                })
            );
        } else {
            return of(null);
        }
    }

    /** Carica la lista paginata delle selettive da mostrare nell'elenco, metodo richiamato dalla griglia */
    public caricaElencoSelettive(event: LazyLoadEvent): void {
        this._loadElencoSelettive(event.rows, event.first, false).subscribe();
    }

    /** Metodo interno che carica la lista delle selettive */
    private _loadElencoSelettive(take: number | undefined, skip: number | undefined, hasNewSelettiva: boolean): Observable<number> {
        return this.selettivaService.apiSelettivaSelettivaListGet({ take: take, skip: skip }).pipe(
            map((selettivaList: Array<SelettivaListItemDto>) => {
                if ((this._selectedSelettivaId == 0 || hasNewSelettiva) && selettivaList.length) {
                    this._selectedSelettivaId = selettivaList[0].id;
                    this.selectedSelettiva = selettivaList[0];
                    this._lastVisibleSelettivaId = selettivaList[0].id;
                }
                if (skip === 0) {
                    // Se Skip è uguale a 0 significa che è la prima lettura oppure è richiesto il refresh della griglia
                    this.selettivaList = selettivaList;
                } else {
                    // Altrimenti aggiungo i record caricati alla lista
                    this.selettivaList.push(...selettivaList);

                    // Utilizzato per far scattare la ChangeDetection
                    this.selettivaList = [...this.selettivaList];
                }
                return this._selectedSelettivaId;
            })
        );
    }

    public getNameByType(type: number): string {
        switch (type) {
            case 1:
                return 'Vigile';
            case 2:
                return 'Autista';
        }
        return '';
    }

    /** Activate or deactivate the night standby mode */
    private nightStandby(): void {
        if (this.nightStandbyTimeout) {
            clearTimeout(this.nightStandbyTimeout);
        }

        // Check if we are in day or night time
        const currentTime = new Date();
        const nextTimeout = new Date();

        // Check if we are in day or night time
        if (currentTime.getHours() >= STANDBY_NIGHT_START || currentTime.getHours() < STANDBY_NIGHT_END) {
            // Night
            this.nightStandbyStatus = true;
            nextTimeout.setDate(currentTime.getDate() + 1);
            nextTimeout.setHours(STANDBY_NIGHT_END, 0, 0, 0);
        } else {
            // Day
            this.nightStandbyStatus = false;
            nextTimeout.setHours(STANDBY_NIGHT_START, 0, 0, 0);
        }

        // Hide page content if we are in night standby mode
        this.pageContent = !this.nightStandbyStatus;

        // Calculate the time remaining until the next timeout
        const timeUntilNextTimeout = nextTimeout.getTime() - currentTime.getTime();

        console.log({
            nightMode: {
                status: this.nightStandbyStatus,
                nextTimeout: timeUntilNextTimeout,
            },
        });

        // Set timeout to do the next check
        this.nightStandbyTimeout = window.setTimeout(() => {
            this.nightStandby();
        }, timeUntilNextTimeout);
    }

    /** Exit from night standby mode for 20 minutes */
    public nighStandbyWakeOnCall(): void {
        this.nightStandbyStatus = false;
        this.pageContent = true;

        if (this.nightStandbyTimeout) {
            clearTimeout(this.nightStandbyTimeout);
        }
    }

    ngOnDestroy(): void {
        this.sub?.unsubscribe();
        this.sub = undefined;
    }

    public getGravita(codice?: string): string {
        if (!codice) {
            return 'noGravityCode';
        }
        const regex = new RegExp('[a-zA-Z]');
        const result = regex.exec(codice);

        return result ? result[0] : 'a';
    }
}
